if (document.querySelectorAll('[data-component="dropdown"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {
        
        if (!Element.prototype.closest) {
            if (!Element.prototype.matches) {
                Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
            }
            Element.prototype.closest = function (s) {
                let el = this;
                let ancestor = this;

                if (!document.documentElement.contains(el)) return null;
                do {
                    if (ancestor.matches(s)) return ancestor;
                    ancestor = ancestor.parentElement;
                } while (ancestor !== null);

                return null;
            };
        }

        const toogleDropdown = () => {
            let toggleClass = document.getElementsByClassName("dropdown");

            for (let i = 0; i < toggleClass.length; i++) {
                let openDropdown = toggleClass[i];

                if (openDropdown.classList.contains('active')) {
                    openDropdown.classList.remove('active');
                }
            }  
        }

        document.addEventListener('click', function (event) {
            if (!event.target.closest('.dropdown')) {
                toogleDropdown();
            } else if (event.target.closest('.dropdown-toggle') && event.target.closest('.active')) {
                event.target.closest('.dropdown').classList.remove('active');
            } else if (event.target.closest('.dropdown')) {
                toogleDropdown();
                event.target.closest('.dropdown').classList.add('active');
            }
        }, false);
    })
}