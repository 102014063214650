if (document.querySelectorAll('[data-component="searchbox"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        function initSearchBox(element) {
            const searchButtons = element.querySelectorAll('[data-toggle="searchbox"]');
            
            searchButtons.forEach((searchButton) => {
                searchButton.addEventListener('click', toggleSearchBox)
            });

            function toggleSearchBox(e) {
                e.preventDefault();

                let navbar = e.target.closest('.navbar');

                if (navbar.classList.contains('searchbox-open')) {
                    closeSearchBox()
                } else {
                    closeSearchBox();
                    openSearchBox(e)
                }
            }

            function openSearchBox(e) {
                e.preventDefault();

                let navbar = e.target.closest('.navbar');
                let searchInput = navbar.querySelector('.searchbox-dialog__input');
                let buttons = document.querySelectorAll('[data-toggle="searchbox"]');
                
                buttons.forEach((searchButton) => {
                    searchButton.setAttribute('aria-pressed', true)
                });

                navbar.classList.add('searchbox-open');
                searchInput.focus();

                document.addEventListener('keyup', keyPressHandler);
            }

            function closeSearchBox() {
                let navbar = document.querySelector('.navbar');
                let buttons = document.querySelectorAll('[data-toggle="searchbox"]');

                buttons.forEach((searchButton) => {
                    searchButton.removeAttribute('aria-pressed')
                });

                navbar.classList.remove('searchbox-open');

                document.removeEventListener('keyup', keyPressHandler);
            }

            function keyPressHandler(e) {
                if (e.key === 'Escape') {
                    closeSearchBox();
                }
            }
        }

        const navbar = document.querySelector('.navbar');
        initSearchBox(navbar);
        
    })
}