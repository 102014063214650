import Choices from "choices.js";

if (document.querySelectorAll('[data-component="choices"]').length > 0) {

    document.addEventListener('DOMContentLoaded', function () {

        const toggleBaseElement = document.querySelector('[data-toggle="choices"]');

        new Choices(toggleBaseElement, {
            allowHTML: true,
            searchPlaceholderValue: 'This is a search placeholder',
        });
    })
}