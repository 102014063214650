if (document.querySelectorAll('[data-component="carousel"]').length > 0) {
  document.addEventListener("DOMContentLoaded", function () {
    $(".slick--home").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      nextArrow:
        '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
      prevArrow:
        '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    $(".slick--featured").slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      dots: true,
      nextArrow:
        '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
      prevArrow:
        '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    });

    $(".slick--browse").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      centerMode: true,
      variableWidth: true,
      nextArrow:
        '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
      prevArrow:
        '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            variableWidth: false,
          },
        },
      ],
    });

    $(".slick--factory").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      nextArrow:
        '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
      prevArrow:
        '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            arrows: false,
            dots: true,
          },
        },
      ],
    });
  });
}
